<template>
	<resource-form :id="id" type="course" plural="courses" v-model="course"></resource-form>
</template>

<script>

import validationRules from "@/util/validation";
import {sync} from "vuex-pathify";
import resourceForm from '@/views/resources/Form'
import models from '@/models'

export default {
	name: "CoursesForm",
	components: {resourceForm},
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		course: sync('courses/course'),
	},
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('courses/getCourse!', this.id)
			} else {
				this.course = new models.Resource('Course')
			}
		}
	}
}
</script>